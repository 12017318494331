import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Camera } from '@ionic-native/camera/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { DbService} from './services/db.service'

import { Clipboard } from '@ionic-native/clipboard/ngx';

import { SocialSharing} from '@ionic-native/social-sharing/ngx';

import { CallNumber } from '@ionic-native/call-number/ngx'; 

import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule} from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule, } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

import { environment } from '../environments/environment';

import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { ArrayToStringPipe } from './pipes/array-to-string.pipe';
import {NgPipesModule} from 'ngx-pipes';

import { Geolocation } from '@ionic-native/geolocation/ngx';

import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';

import { Validators, FormBuilder, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';

import { Calendar } from '@ionic-native/calendar/ngx';

import { IonicStorageModule,Storage  } from '@ionic/storage-angular';

import { Network } from '@ionic-native/network/ngx';

import { JsonLdModule } from 'ngx-seo';



@NgModule({
  declarations: [AppComponent, ArrayToStringPipe],
  entryComponents: [],
  imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }), IonicModule.forRoot(), AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    NgPipesModule,
    JsonLdModule],    
  providers: [
    FirebaseAnalytics,
    GooglePlus,
    AndroidPermissions,
    Camera,
    StatusBar,
	  BarcodeScanner,
    SplashScreen,
    DbService,
    Clipboard,
    SocialSharing,
    CallNumber,    
    TextToSpeech,
    AngularFirestoreModule,   
    HttpClientModule,
    HTTP,
    Geolocation,
    NativeGeocoder, 
    Validators,    
    ReactiveFormsModule,
    Calendar, 
    Storage, 
    Network,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  
    
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule {}
